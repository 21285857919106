import "./src/styles/global.css"
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

export const onClientEntry = () => {
    // Ensure the window object is available
    if (typeof window !== "undefined") {
      window.$zoho = window.$zoho || {};
      window.$zoho.salesiq = window.$zoho.salesiq || { ready: function() {} };
  
      const script = document.createElement("script");
      script.id = "zsiqscript";
      script.src = "https://salesiq.zohopublic.in/widget?wc=siqfcb8312e228263004c34ee53717ce10371e15722465cc750cf6f0e63c272460d";
      script.defer = true;
  
      document.body.appendChild(script);
    }
  };